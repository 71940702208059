define("admin/routes/main/super/locked-accounts", ["exports", "admin/mixins/refreshable-route"], function (_exports, _refreshableRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshableRoute.default, {
    client: Ember.inject.service(),
    model: function model() {
      return this.client.send('GET', 'admin/locked-accounts');
    }
  });

  _exports.default = _default;
});