define("admin/templates/main/super/locked-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dIf9d/ha",
    "block": "{\"symbols\":[\"identity\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-hover header-fixed\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[10,\"class\",\"font-size-12px\"],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"ID\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Failed Attempts\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Locked At\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[10,\"class\",\"font-size-14px\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"failed_attempts\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"locked_at\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[1,[28,\"status-button\",null,[[\"type\",\"class\",\"action\",\"title\"],[\"submit\",\"btn btn-red btn-tiny\",[28,\"action\",[[23,0,[]],\"unlock\",[23,1,[]]],null],\"Unlock\"]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/main/super/locked-accounts.hbs"
    }
  });

  _exports.default = _default;
});